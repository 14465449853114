import * as React from 'react';
import { Howl } from 'howler';
import { fileExists } from '@/utils/fileExists';

interface AudioContextType {
  bgm: string;
  bgmVolume: number;
  soundEffectVolume: number;
  isMutedBGM: boolean;
  isMutedSoundEffect: boolean;
  playBgm: () => void;
  pauseBgm: () => void;
  stopBgm: () => void;
  changeBgm: (bgm: string) => void;
  toggleMute: () => void;
  toggleSoundEffectMute: () => void;
  playSoundEffect: (key: string) => void;
  stopSoundEffect: (key: string) => void;
  changeBGMVolume: (volume: number) => void;
  changeSoundEffectVolume: (volume: number) => void;
}

const AudioContext = React.createContext<AudioContextType | undefined>(
  undefined,
);

interface AudioProviderProps {
  children: React.ReactNode;
}

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const [bgmVolume, setBGMVolume] = React.useState(1);
  const [isMutedBGM, setMutedBGM] = React.useState(false);
  const [isMutedSoundEffect, setMutedSoundEffect] = React.useState(false);

  const [soundEffectVolume, setSoundEffectVolume] = React.useState(0.8);
  const [bgm, setBgm] = React.useState('/audios/bgm_1.mp3');
  const audioRef = React.useRef<Howl>();
  const soundEffectsRef = React.useRef<{ [key: string]: Howl }>({});

  const changeBgm = (bgm: string) => {
    setBgm(bgm);
    audioRef.current?.stop();
    audioRef.current = new Howl({
      src: [bgm],
      autoplay: false,
      loop: true,
      volume: bgmVolume / 10,
      onplay: () => {
        setMutedBGM(false);
      },
    });
    localStorage.setItem('backgroundBGM', bgm);
    if (!isMutedBGM) {
      audioRef.current.play();
    }
  };

  const setupBgm = React.useCallback(async () => {
    const bgmVolume = localStorage.getItem('bgmVolume')
      ? Number(localStorage.getItem('bgmVolume'))
      : 1;
    const soundEffectVolume = localStorage.getItem('soundEffectVolume')
      ? Number(localStorage.getItem('soundEffectVolume'))
      : 1;
    setBGMVolume(bgmVolume);
    setSoundEffectVolume(soundEffectVolume);
    // localStorageに保存されている音量を取得
    const storageBGM = localStorage.getItem('backgroundBGM');
    const defaultBGM = '/audios/bgm_1.mp3';

    await fileExists(storageBGM ?? defaultBGM).then((exists) => {
      const bgmSource = exists ? storageBGM : defaultBGM;
      setBgm(bgmSource ?? defaultBGM);
      audioRef.current = new Howl({
        src: [bgmSource ?? defaultBGM],
        autoplay: false,
        loop: true,
        volume: bgmVolume / 10,
      });
    });
    const bgmMuted = localStorage.getItem('bgmMuted');
    const soundEffectMuted = localStorage.getItem('soundEffectMuted');

    const soundEffectFiles: { [key: string]: string } = {
      timer: '/audios/timer_60s.mp3',
      post: '/audios/post.mp3',
      correct_answer: '/audios/correct.mp3',
      question_start: '/audios/question_start.mp3',
      question_end: '/audios/question_end.mp3',
      incorrect_answer: '/audios/incorrect.mp3',
      no_life: '/audios/no_life.mp3',
      typing: '/audios/typing.mp3',
      click_tab: '/audios/click_tab.mp3',
      click_button: '/audios/click_button.mp3',
    };

    for (const [key, src] of Object.entries(soundEffectFiles)) {
      soundEffectsRef.current[key] = new Howl({
        src: [src],
        autoplay: false,
        loop: false,
        volume: soundEffectVolume / 5,
      });
    }

    if (!audioRef.current) return;
    if (!!bgmMuted && JSON.parse(bgmMuted) === true) {
      setMutedBGM(true);
      return;
    }
    audioRef.current.play();

    if (!!soundEffectMuted && JSON.parse(soundEffectMuted) === true) {
      setMutedSoundEffect(true);
      return;
    }
  }, []);

  // visibilitychangeイベントリスナ
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // バックグラウンドになった場合に停止
      audioRef.current?.pause();
      // あるいはHowler全体をミュートにしたければ
      // Howler.mute(true);
    } else {
      // フォアグラウンドに戻った場合に再開させたい場合のみ
      // sound.play();
    }
  };

  React.useEffect(() => {
    setupBgm();

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false,
    );

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false,
      );

      audioRef.current?.unload();
      for (const sound of Object.values(soundEffectsRef.current)) {
        sound.unload();
      }
    };
  }, []);

  const playBgm = () => {
    audioRef.current?.play();
  };

  const pauseBgm = () => {
    audioRef.current?.pause();
  };

  const stopBgm = () => {
    audioRef.current?.stop();
  };

  const toggleMute = () => {
    setMutedBGM(!isMutedBGM);
    if (isMutedBGM) {
      audioRef.current?.play();
      localStorage.setItem('bgmMuted', 'false');
    } else {
      audioRef.current?.stop();
      localStorage.setItem('bgmMuted', 'true');
    }
  };

  const toggleSoundEffectMute = () => {
    setMutedSoundEffect(!isMutedSoundEffect);
    if (isMutedSoundEffect) {
      localStorage.setItem('soundEffectMuted', 'false');
    } else {
      localStorage.setItem('soundEffectMuted', 'true');
    }
  };

  const changeBGMVolume = (volume: number) => {
    setBGMVolume(volume);
    audioRef.current?.volume(volume / 10);
    localStorage.setItem('bgmVolume', volume.toString());
  };

  const changeSoundEffectVolume = (volume: number) => {
    for (const sound of Object.values(soundEffectsRef.current)) {
      sound.volume(volume / 5);
    }
    setSoundEffectVolume(volume);
    localStorage.setItem('soundEffectVolume', volume.toString());
  };

  const playSoundEffect = (key: string) => {
    if (isMutedSoundEffect) return;
    soundEffectsRef.current[key].play();
  };

  const stopSoundEffect = (key: string) => {
    soundEffectsRef.current[key].stop();
  };

  return (
    <AudioContext.Provider
      value={{
        bgm,
        bgmVolume,
        soundEffectVolume,
        isMutedBGM,
        isMutedSoundEffect,
        playBgm,
        pauseBgm,
        stopBgm,
        changeBgm,
        toggleMute,
        toggleSoundEffectMute,
        playSoundEffect,
        stopSoundEffect,
        changeBGMVolume,
        changeSoundEffectVolume,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = (): AudioContextType => {
  const context = React.useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};
