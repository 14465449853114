import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { ModalLayout, ForwardRefHandlers } from '../ModalLayout';
import SoundSettings from '@/components/Setting/Sound';
import WindowSettings from '@/components/Setting/Window';
import UserSettings from '@/components/Setting/User';
import { SteamUser } from '@/types/user';
import { useAudio } from '@/context/AudioContext';
import ServerSettings from '@/components/Setting/Server';
import { useRouter } from 'next/router';

type TabType = 'display' | 'sound' | 'game' | 'server';

interface Props {
  onClose: () => void;
  onChangeName?: (userId: string, name: string) => void;
  onChangeAvatar?: (user: SteamUser) => void;
}

const tabs: { label: string; value: TabType }[] = [
  { label: 'soundSetting', value: 'sound' },
  { label: 'gameSetting', value: 'game' },
];

export default function SettingModal({
  onClose,
  onChangeName,
  onChangeAvatar,
}: Props) {
  const { playSoundEffect } = useAudio();

  const { t, i18n } = useTranslation();
  const ref = React.useRef<ForwardRefHandlers>(null);
  const [tabType, setTabType] = React.useState<TabType>('sound');

  const handleChangeTab = (tabType: TabType) => () => {
    playSoundEffect('click_button');
    setTabType(tabType);
  };

  return (
    <ModalLayout className="!h-auto" ref={ref} onClose={onClose}>
      <div className="p-3 h-full flex flex-col items-center justify-center se-h:py-4 se-h:px-2">
        <div className="grid grid-cols-2 gap-8 mb-4">
          {tabs.map((tab) => (
            <div
              key={tab.value}
              className={`text-2xl font-bold cursor-pointer relative text-center se-h:text-xs xs:text-base ${
                tabType === tab.value ? 'text-brown-primary' : 'text-gray-400'
              } hover:text-brown-primary hover:opacity-90`}
              style={{ backgroundSize: '100% 180%' }}
              onClick={handleChangeTab(tab.value)}
            >
              <span>{t(tab.label)}</span>
            </div>
          ))}
        </div>
        <div className="h-[400px] text-brown-primary flex flex-col justify-center">
          {tabType === 'sound' && <SoundSettings />}
          {tabType === 'game' && (
            <UserSettings
              onChangeName={onChangeName}
              onChangeAvatar={onChangeAvatar}
            />
          )}
          {tabType === 'server' && <ServerSettings />}
        </div>
      </div>
    </ModalLayout>
  );
}
