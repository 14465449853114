import * as React from 'react';
import { useAudio } from '@/context/AudioContext';
import Radio from '../_inputs/Radio';
import { useTranslation } from 'next-i18next';

const bgmList = [
  { title: 'BGM1', bgm: '/audios/bgm_1.mp3' },
  { title: 'BGM2', bgm: '/audios/bgm_2.mp3' },
];

export default function SoundSettings() {
  const {
    bgm,
    bgmVolume,
    soundEffectVolume,
    isMutedBGM,
    changeBgm,
    changeBGMVolume,
    changeSoundEffectVolume,
    toggleMute,
  } = useAudio();

  const { t } = useTranslation();

  const handleChangeBGMVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    changeBGMVolume(newVolume);
  };

  const handleChangeSoundEffectVolume = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newVolume = parseFloat(e.target.value);
    changeSoundEffectVolume(newVolume);
  };

  return (
    <div>
      <div className="flex flex-col mb-10">
        <div className="flex items-center gap-4 mb-4 text-xl font-medium se-h:text-base">
          <div className="w-[80px]">BGM</div>
          <input
            id="volume"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={bgmVolume}
            className="custom-range w-[50%] h-4 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            onChange={handleChangeBGMVolume}
          ></input>
          <div className="w-[36px]">
            {bgmVolume ? Math.round(bgmVolume * 100) : '-'}
          </div>
          {/* <div
              className="flex items-center gap-4  cursor-pointer"
              onClick={toggleMute}
            >
              <input
                type="checkbox"
                id="custom-checkbox"
                className="custom-checkbox"
                checked={isMutedBGM}
                readOnly
              />
              <label htmlFor="custom-checkbox  border rounded-lg appearance-none" />
              <div>{t('options.sound.mute')}</div>
            </div> */}
        </div>

        <div className="flex items-center gap-4 mb-2 text-xl font-medium se-h:text-base">
          <div className="w-[80px]">{t('options.sound.soundEffect')}</div>
          <input
            id="volume"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={soundEffectVolume}
            className="custom-range w-[50%] h-4 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            onChange={handleChangeSoundEffectVolume}
          ></input>
          <div className="w-[36px]">
            {soundEffectVolume ? Math.round(soundEffectVolume * 100) : '-'}
          </div>
        </div>
      </div>
    </div>
  );
}
