'use client';
import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation, Trans } from 'next-i18next';
import { useSessionUser } from '@/hooks/useSessionUser';
import Loading from '@/components/Loading/Loading';
import Link from 'next/link';
import { useAudio } from '@/context/AudioContext';
import SettingModal from '@/components/_modals/SettingModal';
import { Tooltip } from 'react-tooltip';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useAuth } from '@/context/AuthContext';
import InitAppModal from '@/components/_modals/InitAppModal';
import EdgeButton from '@/components/_buttons/EdgeButton';
import Image from 'next/image';

export default function Page() {
  const { user, login } = useSessionUser();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { noUserName, token, handleSetUser } = useAuth();
  const [isFirstLaunch, setIsFirstLaunch] = React.useState(true);
  const { playSoundEffect } = useAudio();
  const [isDisplaySettingModal, setDisplaySettingModal] = React.useState(false);
  const [displayRoomMenu, setDisplayRoomMenu] = React.useState(false);

  const handleCloseLaunchModal = () => {
    setIsFirstLaunch(false);
    localStorage.setItem('launched', 'true');
  };

  const handleCloseRoomMenu = () => {
    if (!displayRoomMenu) return;
    setDisplayRoomMenu(false);
  };

  const createGuestUser = async (data: { userName: string }) => {
    if (!token) return;
    try {
      await login(data.userName, token);
      handleSetUser();
    } catch (error) {
      console.log(error);
    }
  };

  const startPayment = () => {
    // Flutterアプリを起動（カスタムURLスキームを使用）
    window.location.href = 'deduceTogether://start-payment';
  };

  // 決済完了時の処理
  const paymentCompleted = () => {
    alert('決済完了');
  };

  // 広告見終わった時の処理
  const adWatchComplete = () => {
    alert('広告見終わり完了');
  };

  const handleShowSettingModal = React.useCallback(() => {
    setDisplaySettingModal(true);
  }, []);

  const handleCloseSettingModal = React.useCallback(() => {
    setDisplaySettingModal(false);
  }, []);

  const clickHandler = () => {
    // @ts-ignore
    console.log(window, window.flutter_inappwebview);
    // @ts-ignore
    // window.flutter_inappwebview.callHandler('showAlert', '');
    window.flutter_inappwebview.callHandler('showRewardedAd', '');
    // @ts-ignore
  };

  function onRewardEarned(event: any) {
    console.log(event);
  }

  React.useEffect(() => {
    if (noUserName) {
      createGuestUser({ userName: '@名無し' });
      return;
    }
    setIsFirstLaunch(!localStorage.getItem('launched'));
    const handleMessage = (event: any) => {
      if (event.origin !== process.env.NEXT_PUBLIC_APP_URL) return;

      const { type, payload } = event.data;

      // メッセージの種類で分岐
      switch (type) {
        case 'paymentCompleted':
          paymentCompleted();
          break;

        case 'adWatchComplete':
          adWatchComplete();
          break;

        default:
          break;
      }
    };
    window.addEventListener('message', handleMessage as EventListener);
    window.addEventListener(
      'flutterInAppWebViewPlatformReady',
      function (event) {
        const args = [1, true, ['bar', 5], { foo: 'baz' }];
        // @ts-ignore
        window.flutter_inappwebview.callHandler('myHandlerName', ...args);
      },
    );
    window.addEventListener(
      'onRewardEarned',
      (event) => {
        console.log(event);
      },
      false,
    );
    // クリーンアップ処理
    return () => {
      window.removeEventListener('message', handleMessage as EventListener);
    };
  }, [noUserName]);

  React.useEffect(() => {
    if (router.query.name) {
      localStorage.setItem('userName', String(router.query.name));
      localStorage.setItem('launched', 'true');
      setIsFirstLaunch(false);
    }
  }, [router.query.name]);

  return (
    <>
      {user ? (
        <>
          {isFirstLaunch ? (
            <InitAppModal onClose={handleCloseLaunchModal} />
          ) : (
            <div className="relative h-full flex flex-col justify-center items-center gap-16 galaxy-h:gap-4">
              <div className="relative flex justify-center ml-8">
                <img
                  className="w-[300px] h-[300px] md:max-w-[500px] md:h-[300px] xs:max-w-[300px] xs:h-[280px] sm:max-w-[500px] sm:h-[300px] se-h:max-w-[240px] se-h:h-[240px] galaxy-h:max-w-[240px] galaxy-h:h-[240px] "
                  src={`/images/title_logo_${i18n.language}.png`}
                  alt="logo"
                />
              </div>

              <div className="flex flex-col gap-4">
                {/* <Link href={'/single-mode'}>
                  <EdgeButton className="!w-[168px] h-[56px] text-base bg-contain">
                    シングルプレイ
                  </EdgeButton>
                </Link> */}
                <Link href={'/lobbies'}>
                  <EdgeButton className="!w-[168px] h-[56px] text-base bg-contain">
                    マルチプレイ
                  </EdgeButton>
                </Link>
                <Link href={'/original-questions'}>
                  <EdgeButton className="!w-[168px] h-[56px] text-base bg-contain">
                    オリジナル問題
                  </EdgeButton>
                </Link>
              </div>
              <div className="flex flex-col items-start gap-2">
                <div
                  className="room-icon flex hover-scale-1_3 items-center gap-4 justify-end text-xl  text-brown-secondary font-bold cursor-pointer active-scale-90"
                  style={{ letterSpacing: '2px' }}
                  onClick={handleShowSettingModal}
                >
                  <Image
                    src="/images/icon_setting.svg"
                    width={40}
                    height={40}
                    alt="to home"
                  />
                  {t('userSettings')}
                </div>
                <a
                  href={t('discordLink')}
                  className="room-icon flex hover-scale-1_3 items-center gap-4 justify-end text-xl  text-brown-secondary font-bold cursor-pointer active-scale-90"
                  style={{ letterSpacing: '2px' }}
                >
                  <Image
                    src="/images/icon_discord.svg"
                    width={40}
                    height={40}
                    alt="to home"
                  />
                  {t('discord')}
                </a>
              </div>

              {isDisplaySettingModal && (
                <SettingModal onClose={handleCloseSettingModal} />
              )}
              <Tooltip id="my-tooltip" className="z-10" />
            </div>
          )}
        </>
      ) : (
        <>
          <Loading title="Loading" size={8} />
        </>
      )}
    </>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], null, [
        'us',
        'jp',
        'kr',
      ])),
    },
  };
}
