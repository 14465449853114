import * as React from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useModalOrders } from '@/hooks/useModalOrders';
import { appColor } from '@/styles/color';
import { Kaisei_Opti } from 'next/font/google';

const kaisei = Kaisei_Opti({ weight: '400', subsets: ['latin'] });

interface Props {
  width?: number;
  background?: string;
  className?: string;
  styles?: React.CSSProperties;
  isBackdropClose?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

export interface ForwardRefHandlers {
  close: () => void;
}

interface ModalProps {
  $modalWidth?: number;
  $modalBackground?: string;
}

const BackdropFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BackdropFadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const ModalFadeIn = keyframes`
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const ModalFadeOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(0.9);
  }

  30%{
    transform: scale(0.94);
  }


  100% {
    opacity: 0;
    transform: scale(0.5);
  }
`;

const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.9);
  animation: ${BackdropFadeOut} 0.3s ease-in-out;
  &.active {
    animation: ${BackdropFadeIn} 0.3s ease-in-out;
    opacity: 1;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Modal = styled.div<ModalProps>`
  min-width: 300px;
  /* min-height: 200px; */
  max-height: 90vh;
  width: ${({ $modalWidth }) => $modalWidth}px;
  max-width: 90vw;
  color: ${appColor.text.black};
  /* background: ${({ $modalBackground }) => $modalBackground}; */
  border-radius: 8px;
  transform-origin: center;
  transform: scale(1);
  animation: ${ModalFadeOut} 0.4s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: visible;

  &.active {
    animation: ${ModalFadeIn} 0.4s ease-in-out;
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    background-clip: padding-box;
  }
`;

export const ModalLayout = React.forwardRef<ForwardRefHandlers, Props>(
  (
    {
      children,
      onClose,
      width = 480,
      background = '#fff',
      styles,
      className,
      isBackdropClose = true,
    },
    forwardRef,
  ) => {
    const [isDisplay, setIsDisplay] = React.useState(true);
    const [modalId, setModalId] = React.useState(uuidv4());
    const [modals, addModal, removeModal, isForeground] = useModalOrders();

    const handleClose = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!onClose) return;
      setIsDisplay(false);
    };

    const handleClickBackdrop = (e: React.MouseEvent) => {
      if (!isBackdropClose) return;
      handleClose(e);
    };

    const handleEscape = React.useCallback(
      (event: KeyboardEvent) => {
        if (!isForeground(modalId)) {
          return;
        }
        if (['Escape', 'Esc'].includes(event.key)) {
          event.preventDefault();
          setIsDisplay(false);
        }
      },
      [modalId, isForeground],
    );

    const modalClassName = React.useMemo(() => {
      if (className) {
        if (isDisplay) {
          return (
            'active' +
            ' ' +
            'relative border border-gold-300 h-full bg-cover bg-center bg-no-repeat' +
            ' ' +
            className
          );
        } else {
          return className;
        }
      } else {
        if (isDisplay) {
          return (
            'active' +
            ' ' +
            'relative border border-gold-300 h-full bg-cover bg-center bg-no-repeat'
          );
        } else {
          return '';
        }
      }
    }, [isDisplay, className]);

    React.useImperativeHandle(forwardRef, () => {
      return {
        close: () => {
          setIsDisplay(false);
        },
      };
    });

    React.useEffect(() => {
      if (!isDisplay && typeof onClose === 'function') {
        setTimeout(() => {
          removeModal(modalId);
          onClose();
        }, 290);
      }
    }, [modalId, isDisplay, onClose, removeModal]);

    React.useEffect(() => {
      document.addEventListener('keydown', handleEscape, false);
      return () => {
        document.removeEventListener('keydown', handleEscape, false);
      };
    }, [handleEscape]);

    React.useEffect(() => {
      if (!modalId) return;
      addModal(modalId);
    }, [modalId]);

    return createPortal(
      <ModalWrapper className={`${kaisei.className}`}>
        <Backdrop
          onClick={handleClickBackdrop}
          className={isDisplay ? 'active' : ''}
        />
        <Modal
          className={modalClassName}
          style={styles}
          $modalWidth={width}
          $modalBackground={background}
        >
          {onClose && (
            <button
              className="absolute -top-[40px] -right-[40px] 2xs:-top-[20px] 2xs:-right-[20px] xs:-top-[20px] xs:-right-[20px] z-10 bg-[url('/images/button_close.png')] h-[88px] w-[88px] md:w-[44px] md:h-[44px] lg:w-[44px] lg:h-[44px] 2xs:w-[44px] 2xs:h-[44px] xs:w-[44px] xs:h-[44px] bg-cover bg-center bg-no-repeat"
              onClick={handleClose}
            />
          )}

          {children}
        </Modal>
      </ModalWrapper>,
      document.body,
    );
  },
);
ModalLayout.displayName = 'ModalLayout';
