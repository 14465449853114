import * as React from 'react';
import api, { axios } from '@/libs/api';
import { useTranslation } from 'next-i18next';

import { useSessionUserStore } from './stores/useSessionUserStore';
import { toast } from 'react-toastify';
import { BASEURL } from '@/settings/endpoint';
import { useRouter } from 'next/router';
import useAskCount from './stores/useAskCountStore';

export function useSessionUser() {
  const { t } = useTranslation();
  const router = useRouter();
  const [loginError, setLoginError] = React.useState(false);
  const { user, setUser, isFetching, isCompleted, setFetching, setCompleted } =
    useSessionUserStore();
  const { setAppUser } = useAskCount();

  const login = async (name: string, token: string) => {
    const res = await api.post('/api/v1/users/firebase-login', {
      name,
      token,
    });
    if ('error' in res) return;
    localStorage.setItem('userName', name);
    if ('correctAnswerCount' in res && res.connectedAccount === undefined) {
      setAppUser();
    }
    setUser(res);
  };

  const fetchAvatars = React.useCallback(async () => {
    const res = await api.get('/api/v1/users/own-avatars');
    if ('error' in res) return;
    return res;
  }, []);

  const updateAvatar = async (avatarId: string) => {
    if (!user) return;
    const res = await toast.promise(
      api.patch('/api/v1/users/avatar', {
        avatarId,
      }),
      {
        success: t('success.changeAvatar'),
      },
    );
    if ('error' in res) {
      toast.warning(t('error.changeAvatar'));
      return;
    }
    setUser({ ...user, ...res });
    return res;
  };

  const refresh = React.useCallback(async () => {
    // setFetching(true);
    // const res = await api.get('/api/v1/session-user');
    // setFetching(false);
    // if ('error' in res) {
    //   setCompleted(true);
    //   return;
    // }
    // setUser(res);
  }, []);

  const signup = async (name: string) => {
    // const res = await toast.promise(api.post('/api/v1/users', { name }), {
    //   pending: 'アカウント作成中',
    //   error: 'エラーが発生しました。再度お試しください ',
    // });
    // if ('error' in res) {
    //   toast.warning(res.error.message[0]);
    //   return;
    // }
    // refresh();
    // return res;
  };

  const signIn = async (data: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    // const { email, password, remember } = data;
    // const res = await toast.promise(
    //   api.post('/api/v1/users/login', {
    //     email,
    //     password,
    //     remember,
    //   }),
    //   {
    //     pending: 'ログイン中',
    //     error: 'エラーが発生しました。再度お試しください ',
    //   },
    // );
    // if ('error' in res) {
    //   toast.warning(res.error.message[0]);
    //   return;
    // }
    // refresh();
  };

  const signOut = async () => {
    // const res = await toast.promise(api.post('/api/v1/users/logout'), {
    //   pending: 'ログアウト中',
    //   error: 'エラーが発生しました。再度お試しください ',
    // });
    // if (typeof res !== 'boolean') {
    //   return;
    // }
    // setUser(null);
    // refresh();
    // return res;
  };

  const updateUserName = async (name: string) => {
    if (!user) return;
    const res = await toast.promise(
      api.patch('/api/v1/users/name', {
        name,
      }),
      {
        error: 'エラーが発生しました。再度お試しください ',
      },
    );
    if ('error' in res) {
      toast.warning(t('error.changeUserError'));
      return;
    }
    toast.success(t('success.changeUserName'));
    setUser({ ...user, name });
    localStorage.setItem('userName', name);
  };

  const updateEmail = async (email: string) => {
    if (!user) return;
    const res = await toast.promise(
      api.patch('/api/v1/users/email', { email }),
      {
        pending: '変更中',
        success: 'メールアドレス宛に確認メールを送信しました',
        error: 'エラーが発生しました。再度お試しください ',
      },
    );
    if (typeof res !== 'boolean') {
      toast.warning('メールアドレスの変更に失敗しました');
      return;
    }
  };

  const updatePassword = async (
    password: string,
    password_confirmation: string,
  ) => {
    if (!user) return;
    const res = await toast.promise(
      api.patch('/api/v1/users/password', {
        password,
        password_confirmation,
      }),
      {
        pending: '変更中',
        success: 'パスワードを変更しました',
        error: 'エラーが発生しました。再度お試しください ',
      },
    );

    if (typeof res !== 'boolean') {
      toast.warning('パスワードの変更に失敗しました');
      return;
    }
    setUser({ ...user });
  };

  return {
    user,
    isFetching,
    isCompleted,
    loginError,
    setFetching,
    setUser,
    refresh,
    signup,
    signIn,
    login,
    signout: signOut,
    updatePassword,
    updateEmail,
    updateUserName,
    fetchAvatars,
    updateAvatar,
  };
}
