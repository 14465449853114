import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../firebase';
import { useSessionUser } from '@/hooks/useSessionUser';
import { SteamUser } from '@/types/user';
import { useRouter } from 'next/router';
import EdgeButton from '@/components/_buttons/EdgeButton';
import { AudioProvider } from './AudioContext';

interface AuthContextProps {
  user: SteamUser | null;
  token: string | null;
  loading: boolean;
  noUserName: boolean;
  handleSetUser: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { user, login } = useSessionUser();
  const [noUserName, setNoUserName] = useState(false);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const handleSetUser = () => {
    setNoUserName(false);
  };

  const handleReload = () => {
    window.location.reload();
  };

  const _login = async () => {
    try {
      const user = await signInAnonymously(auth);
      const token = await user.user?.getIdToken();
      setIdToken(token);
      const storedUserName = localStorage.getItem('userName');
      if (!storedUserName) {
        setNoUserName(true);
        return;
      }
      await login(storedUserName, token);
    } catch (error) {
      console.error(error);
      setErrorMessage('ログインに失敗しました。');
    }
    setLoading(false);
  };

  useEffect(() => {
    _login();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, token: idToken, loading, noUserName, handleSetUser }}
    >
      {errorMessage ? (
        <AudioProvider>
          <div
            className="fixed left-[50%] flex flex-col text-white items-center gap-4 justify-center"
            style={{ top: '50%', transform: 'translate(-50%, -50%' }}
          >
            <div className="text-2xl">{errorMessage}</div>
            <EdgeButton onClick={handleReload}>ページを更新</EdgeButton>
          </div>
        </AudioProvider>
      ) : (
        <> {children}</>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
