import * as React from 'react';
import classNames from 'classnames';
import { useAudio } from '@/context/AudioContext';

interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  children: React.ReactNode;
  className?: string;
  isCancel?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent) => void;
}

export default function EdgeButton({
  children,
  type = 'button',
  className = 'h-[100px] bg-contain',
  isCancel = false,
  disabled,
  style,
  onClick,
}: Props) {
  const buttonImage = isCancel
    ? 'bg-[url("/images/button_secondary_dark.png")]'
    : 'bg-[url("/images/button.png")]';
  const defaultClass = `${buttonImage} edge-button active-scale-90 text-2xl bg-auto bg-center bg-no-repeat w-[288px] text-brown-secondary text-stroke opacity-80`;
  const hoverClass = 'opacity-100';
  const disabledClass = '!opacity-50';
  const { playSoundEffect } = useAudio();

  const buttonClass = classNames(defaultClass, className, {
    [hoverClass]: !disabled,
    [disabledClass]: disabled,
  });

  const handleClick = (e: React.MouseEvent) => {
    playSoundEffect('click_button');
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={type}
      className={className ? `${buttonClass} ${className}` : buttonClass}
      disabled={disabled}
      style={style}
      onClick={handleClick}
    >
      <p className="mb-2">{children}</p>
    </button>
  );
}
