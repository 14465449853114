import * as React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  hasIcon?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const defaultClassName =
    'p-2 pl-4 outline-none text-white border-2 border-gold-300 rounded-full bg-gray-900' +
    ' ' +
    props.className;

  return (
    <input
      {...props}
      className={
        props.hasIcon ? defaultClassName + ' ' + 'pr-10' : defaultClassName
      }
      type={props.type ?? 'text'}
      ref={ref}
    />
  );
});

Input.displayName = 'Input';

export default Input;
