import {
  createOriginalQuestionSchema,
  createOriginalQuestionItemSchema,
} from '@/schema/validation';
import yup from '@/yup.locale';

export enum OriginalQuestionType {
  NORMAL = 0,
  RANDOM = 1,
}

export type CreateOriginalQuestionParams = yup.InferType<
  typeof createOriginalQuestionSchema
>;

export type CreateOriginalQuestionItemParams = yup.InferType<
  typeof createOriginalQuestionItemSchema
>;

export type EditOriginalQuestionItemParams = yup.InferType<
  typeof createOriginalQuestionItemSchema
>;

export interface DeleteOriginalQuestionParams {
  gyakunatorOriginalQuestionId: string;
}

export interface OriginalQuestion {
  id: string;
  title: string;
  description: string;
  itemsCount: number;
  userId: string;
  code: string | null;
  type: OriginalQuestionType;
  isPublished: boolean;
  goodCount: number;
  badCount: number;
  gamePlayedCount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface OriginalQuestionItem {
  id: string;
  description: string;
  gyakunatorOriginalQuestionId: string;
  theme: string;
  themeDetail?: string;
  answer: string;
  order: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface TestReply {
  message: string;
  reply: string;
}
