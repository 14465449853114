import * as React from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { ForwardRefHandlers, ModalLayout } from '../ModalLayout';
import { FullScreenModalLayout } from '../FullscreenModalLayout';
import EdgeButton from '@/components/_buttons/EdgeButton';
import Input from '@/components/_inputs/Input';
import { useForm } from 'react-hook-form';
import { useSessionUser } from '@/hooks/useSessionUser';
import { toast } from 'react-toastify';

interface Props {
  onClose: () => void;
}

export default function InitAppModal({ onClose }: Props) {
  const { t } = useTranslation();
  const { updateUserName } = useSessionUser();

  const { register, handleSubmit } = useForm<{ userName: string }>({
    defaultValues: {
      userName: '',
    },
  });

  const [isNoCollaborateSteam, setIsNoCollaborateSteam] = React.useState(false);

  const handleClickNoCollaborateSteam = () => {
    setIsNoCollaborateSteam(true);
  };

  const ref = React.useRef<ForwardRefHandlers>(null);

  const updateUser = async (data: { userName: string }) => {
    try {
      await updateUserName(data.userName);
      handleClose();
    } catch (error) {
      toast.error('ユーザー名の変更に失敗しました。');
    }
  };

  const handleClose = React.useCallback(() => {
    ref.current?.close();
  }, []);

  return (
    <FullScreenModalLayout ref={ref} hideCloseButton onClose={onClose}>
      {isNoCollaborateSteam ? (
        <form
          className="h-full flex items-center justify-center"
          onSubmit={handleSubmit(updateUser)}
        >
          <div>
            <div className="p-2 pt-0 mb-4">
              <label className="text-left font-bold mb-1 text-brown-primary block text-base custom-label">
                {t('createUser.nickname')}
              </label>
              <Input
                className="text-base w-full h-[56px] custom-input"
                spellCheck={false}
                placeholder={t('createUser.nicknamePlaceholder')}
                autoComplete="false"
                {...register('userName', { required: true, maxLength: 255 })}
              />
            </div>
            <div className="flex justify-center">
              <EdgeButton
                className="!w-[120px] h-[60px]"
                type={'submit'}
                style={{ backgroundSize: '100% 100%' }}
              >
                {t('register')}
              </EdgeButton>
            </div>
          </div>
        </form>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 h-full text-2xl text-white">
          <div className="flex justify-center gap-4">
            <a href={process.env.NEXT_PUBLIC_STEAM_OAUTH_URL}>
              <EdgeButton
                className="!w-[140px] h-[60px] text-lg pt-1"
                style={{ backgroundSize: '100% 100%' }}
              >
                Steamと連携
              </EdgeButton>
            </a>
            <EdgeButton
              className="!w-[140px] h-[60px] text-lg pt-1"
              style={{ backgroundSize: '100% 100%' }}
              onClick={handleClickNoCollaborateSteam}
            >
              連携しない
            </EdgeButton>
          </div>
          <div className="text-lg px-8 text-center">
            {' '}
            Steamアカウントと連携することで、Steamアカウントの情報で遊ぶことができます。
          </div>
        </div>
      )}
    </FullScreenModalLayout>
  );
}
