// store.ts
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface AppState {
  askCount: number;
  loginDate: string;
  appUser: boolean;
  // アクション
  decreaseAskCount: () => void;
  resetAskCount: () => void;
  setLoginDate: (date: string) => void;
  setAppUser: () => void;
}

const useAskCount = create<AppState>()(
  persist(
    (set) => ({
      // 状態の初期値
      askCount: 20,
      loginDate: new Date().toISOString(),
      appUser: false,
      // アクションの定義
      decreaseAskCount: () =>
        set((state) => ({ askCount: state.askCount - 1 })),
      resetAskCount: () => set({ askCount: 20 }),
      setLoginDate: (date: string) => set({ loginDate: date }),
      setAppUser: () => set({ appUser: true }),
    }),
    {
      name: 'app-storage', // localStorageのキー
      getStorage: () => localStorage, // 永続化先をlocalStorageに指定
      // 初回起動時にloginDateを設定（既に初期値で設定しているためオプションは不要かもしれません）
      onRehydrateStorage: () => (state) => {
        if (state) {
          if (!state.loginDate) {
            state.setLoginDate(new Date().toISOString());
          }
        }
      },
    },
  ),
);

export default useAskCount;
