import { QuestionRoom } from '@/types/rooms';
import { SteamUser } from '@/types/user';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface SessionUserStoreState {
  user: (SteamUser & { rooms?: QuestionRoom[] }) | null;
  modalIds: string[];
  isFetching: boolean;
  isCompleted: boolean;
  userName: string | null;
  setUser: (user: SteamUser | null) => void;
  setModalIds: (modalIds: string[]) => void;
  setFetching: (isFetching: boolean) => void;
  setCompleted: (isCompleted: boolean) => void;
}

export const useSessionUserStore = create<SessionUserStoreState>()(
  devtools((set) => ({
    user: null,
    setUser: (user: SteamUser | null) =>
      set(() => ({ user, isCompleted: true })),
    setCompleted: (isCompleted: boolean) => set(() => ({ isCompleted })),
    modalIds: [],
    setModalIds: (modalIds: string[]) => set(() => ({ modalIds })),
    isFetching: false,
    setFetching: (isFetching: boolean) => set(() => ({ isFetching })),
    isCompleted: false,
    userName: null,
  })),
);
